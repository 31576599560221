import React from 'react'
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'
import BaseGrid from '../components/base-grid';
import ImageFull from '../components/image-full';
import logoSvg from '../images/content/books/napis.svg';
import { Link } from "gatsby"

import content from '../content'

export default function Postcards({ data, pageContext: { items, filesPathRelative, slug } }) {
  const bookSection = content.find(({ dir }) => dir === 'books')

  const bookItems = bookSection.items;

  const imagesByItemId = bookItems.reduce(
    (acc, item) => {
      const { id } = item

      return {
        ...acc,
        [id]: data.allFile.nodes.filter(({ relativePath }) => relativePath.indexOf(id) > -1),
      }
    },
    {}
  )

  return (
    <Layout fixedBottom={
      <ImageFull src={logoSvg} />
    }>
      <BaseGrid>
        {bookItems.map((item) => imagesByItemId[item.id].map(image =>
          <Link to={`${bookSection.slug}/${item.id}`} style={{ display: 'block' }}>
            <Img
              key={item.id + image.originalName}
              fluid={image.childImageSharp.fluid}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Link>
        ))}
      </BaseGrid>
    </Layout>
  )
}



export const query = graphql`
  query BookImages {
    allFile(filter: {relativePath: {glob: "content/books/**/preview*.png"}}) {
      nodes {
        relativePath
        childImageSharp {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
